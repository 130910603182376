body {
  color: #feeeed !important;
  background-color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: monospace;
  color: #feeeed;
}

h3.tagline {
  width: 80%;
  margin: 0 auto;
}

.scropgen-container {
  width: 50%;
  margin: 0 auto;
  padding: 20px 50px;
  background-color: white;
  border: 10px groove grey;
  position: relative;
}

h2.scropgen-title {
  color: black;
}

@keyframes rotateNewTagBackAndForth {
  0% {
    transform: rotateZ(15deg);
  }
  50% {
    transform: rotateZ(-15deg);
  }
  100% {
    transform: rotateZ(15deg);
  }
}

h2.scropgen-title::after {
  content: 'New!';
  color: yellow;
  text-shadow: 0 2px 1px black;
  font-size: xxx-large;
  position: absolute;
  top: -30px;
  right: -56px;
  transform: rotateZ(15deg);
  animation: rotateNewTagBackAndForth 3s infinite;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-head {
  grid-area: header;
}
.content {
  grid-area: content;
  justify-content: center;
  align-items: center;
}

.content h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

label {
  user-select: none;
}

.main-nav {
  grid-area: nav;
}
.side {
  grid-area: sidebar;
}
.ad {
  grid-area: ad;
}
.main-footer {
  grid-area: footer;
}

.grid-wrapper {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'header'
    'nav'
    'sidebar'
    'content'
    'footer';
}

@media (min-width: 700px) {
  .grid-wrapper {
    grid-template-columns: 1fr 7fr 1fr;
    grid-template-areas:
      'header  header  header'
      'sidebar content a'
      'sidebar content a'
      'footer  footer  footer';
  }
  nav ul {
    flex-direction: column;
  }
}

.main-footer {
  font-size: 0.7em;
}

.generate-name {
  padding: 4px;
}

.scorp-name {
  margin: 5px 0;
  font-size: 1.25em;
}

.explanatory-text {
  text-align: left;
  margin-bottom: 30px;
}

.select-scorp_label {
  margin-right: 5px;
}

.select-scorp_button {
  margin-left: 5px;
}

.random-scorp-button {
  margin-bottom: 25px;
}

.scorp-tools {
  margin-top: 50px;
}

.color-info {
  user-select: none;
  display: block;
  box-sizing: border-box;
  margin: 2px 0;
  cursor: all-scroll;
}

.color-info_textbox {
  padding: 9px 0;
}

.color-info_textbox.light {
  font-weight: bold;
  color: rgb(236, 236, 236);
  text-shadow: 1px 2px 5px #000000;
}
.color-info_textbox.dark {
  font-weight: bold;
  color: rgb(36, 36, 36);
  text-shadow: 1px 2px 5px #cccccc;
}

.color-info_text {
  width: 90%;
  margin: 0 auto;
  font-family: monospace;
}

.color-info_scorp-list {
  display: none;
}

.color-info_show-hide-button {
  height: 0;
  display: none;
}

.color-info_show-hide-button:checked ~ div.color-info_scorp-list {
  display: block;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.color-info_rare-star-container {
  animation: flash 0.5s linear infinite;
  margin-bottom: 5px;
}

.color-info_rare-star {
  text-shadow: none;
}

.color-info_scorps-of-color-header {
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: rgb(36, 36, 36);
  text-shadow: 1px 2px 5px #cccccc;
  background-color: #feeeed;
}

.color-info_scorps-of-color-container,
.filtered-scorpions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.color-info_scorps-of-color-element,
.filtered-scorpions-div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100%;
  position: relative;
}

.color-info_scorps-of-color-element a {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.color-info_scorps-of-color-container.light .color-info_scorps-of-color-element a {
  color: rgb(236, 236, 236);
}
.color-info_scorps-of-color-container.dark .color-info_scorps-of-color-element a {
  color: rgb(36, 36, 36);
}

div.light {
  color: rgb(236, 236, 236);
}
div.dark {
  color: rgb(36, 36, 36);
}

.filter-by-color {
  text-align: left;
}

.color-distribution-tool button.scroll-to-top {
  position: fixed;
  left: 0px;
  bottom: 25px;
  z-index: 1;
}

.color-distribution-tool button.expand-all {
  position: fixed;
  left: 0px;
  bottom: 75px;
  z-index: 1;
}

.color-distribution-tool button.collapse-all {
  position: fixed;
  left: 0px;
  bottom: 50px;
  z-index: 1;
}

.color-distribution-tool button.reset-all-filters {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 1;
}

.filters_container {
  margin: 10px auto;
  max-width: 900px;
}

.filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  column-width: 160px;
  gap: 10px;
  justify-content: center;
}

.filter_segment {
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
}

.filters_link {
  text-align: center;
}

.filters_link a {
  text-decoration: none;
}
